import './skip-link-focus-fix.js';
import 'script-loader!slick-carousel';
import * as basicScroll from 'basicscroll';

(function($) {

	// Track scroll position through CSS variable, for page header+home hero parallax
	const instance = basicScroll.create({
		elem: document.querySelector('.parallax-bg'),
		from: '0',
		to: 'bottom-center',
		props: {
			'--top': {
				from: '0',
				to: '10vh',
			}
		}
	});
	instance.start();

	$(document).ready(function() {

		headerEffects();
		searchToggle();
		sidebarMenu();
		inputEffects();
		menuPopupShortcode();
		pageAnchor();

		$(window).on('scroll', function() {
			headerEffects();
		});
	});

	// Header scroll effects
	function headerEffects() {
		var navbar = $('#masthead');

		var scrollPosition = $(window).scrollTop();

		if(scrollPosition > 100)
			navbar.addClass('site-header--scrolled');
		else
			navbar.removeClass('site-header--scrolled');
	}

	// The primary menu sidebar
	function sidebarMenu() {
		var toggle  = $('#sidebar-toggle'),
			content = $('#content'),
			header  = $('#masthead'),
			sidebar = $('#primary-menu');

		// Open-close lateral menu clicking on the menu icon
		toggle.on('click', function(event){
			event.preventDefault();
			
			toggle.toggleClass('site-header__menu-toggle--open');
			header.toggleClass('lateral-menu-is-open');
			content.toggleClass('lateral-menu-is-open');
			sidebar.toggleClass('lateral-menu-is-open');
		});

		// Close lateral menu clicking outside the menu itself
		content.on('click', function(event){
			toggle.removeClass('site-header__menu-toggle--open');
			header.removeClass('lateral-menu-is-open');
			content.removeClass('lateral-menu-is-open');
			sidebar.removeClass('lateral-menu-is-open');
		});

		// Submenu toggles
		sidebar.find('.menu-item-has-children').each(function( index ) {
			var menuItem = $(this),
				toggle   = $(this).children('.submenu-toggle'),
				submenu  = menuItem.children('.sub-menu');

				
			if(menuItem.hasClass('current-menu-ancestor')) {
				menuItem.addClass('menu-item--open');
			}
			
			toggle.click(function() {
				menuItem.toggleClass('menu-item--open');
				submenu.slideToggle(200, 'linear');
			});
		});
	}

	// Header search toggle
	function searchToggle() {
		var header = $('#masthead');

		$("#search-toggle").on('click', function() {
			header.toggleClass('site-header--search-open');
			$("#header-search").focus();
		});

		$('body').on('click', function(event) {
			if( !$(event.target).is('#search-toggle, #header-search') ) {
				header.removeClass('site-header--search-open');
			}
		});
	}

	// Form input label effect
	function inputEffects() {
		$(".ginput_container input[type=text], .ginput_container input[type=email], .ginput_container input[type=number], .ginput_container textarea").focus(function(){
			var label = $(this).parent().prev('.gfield_label');
			label.addClass('gfield_label--focus');
		}).blur(function(){
			if(!$(this).val()) {
				var label = $(this).parent().prev('.gfield_label');
				label.removeClass('gfield_label--focus');
			}
		})

		$(document).bind('gform_post_render', function(){
			inputEffects();
			$(".ginput_container input[type=text], .ginput_container input[type=email], .ginput_container input[type=number], .ginput_container textarea").each(function() {
				if($(this).val()) {
					var label = $(this).parent().prev('.gfield_label');
					label.addClass('gfield_label--focus');
				}
			});
		});
	}

	// Featured meal category accordion
	function featuredMealCategory() {
		$('.featured-category__more-link').on('click', function(event) {
			event.preventDefault();

			var content = $(this).prev('.featured-category__more-content');
			content.slideToggle();
			if($(this).text() == 'Read More') {
				$(this).text('Show Less');
			} else {
				$(this).text('Read More');
			}
		});
	}

	// Menu popup button shortcode
	function menuPopupShortcode() {
		$('.menu-button').on('click', function(event) {
			event.preventDefault();

			var category = $(this).data('category'),
			    popup    = $('#menu-popup-' + category),
			    parent   = $(this).parent();
			popup.addClass('featured-category__popup--show')
			popup.appendTo('body');
			$('body').addClass('no-scroll');

			$('.featured-category__popup-close,.featured-category__popup-overlay').on('click', function() {
				popup.removeClass('featured-category__popup--show')
				popup.appendTo(parent);
				$('body').removeClass('no-scroll');
			});
		});
	}

	// Scroll to page anchor location with offset
	function pageAnchor() {
		if(window.location.hash) {
			var hash         = window.location.hash.substring(1),
			    pageAnchor   = $('.page-anchor[data-anchor="' + hash + '"]'),
			    navbarHeight = $('#masthead').outerHeight(true) + $('#masthead').offset().top;

				$('body,html').animate({
					scrollTop: pageAnchor.offset().top - navbarHeight
				}, 400)
		}  
	}

})(jQuery);